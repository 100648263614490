<template>
  <div v-if="mInvoice.StatusId === 0">
    <message
      :message="
        'Check if the invoice is correct and accept it to create a final invoice'
      "
    />
    <div
      class="actionBar has-background-white-ter has-padding has-margin-bottom has-text-centered"
    >
      <a
        @click="rejectInvoice"
        class="button"
        :class="{ 'is-loading': isRejecting }"
        :disabled="isRejecting || isAccepting"
        >Reject</a
      >
      <a
        @click="acceptInvoice"
        class="button is-success"
        :class="{ 'is-loading': isAccepting }"
        :disabled="isRejecting || isAccepting"
        >Accept</a
      >
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import invoiceProvider from '@/providers/invoice'

const Message = () => import('@/components/UI/Message')

export default {
  components: {
    message: Message,
  },

  props: {},

  data() {
    return {
      isAccepting: false,
      isRejecting: false,
      mInvoice: null,
    }
  },

  computed: {
    ...mapState('invoiceStore', ['invoice']),
  },

  created() {
    this.mInvoice = JSON.parse(JSON.stringify(this.invoice))
  },

  methods: {
    ...mapMutations('invoiceStore', ['setInvoice']),

    rejectInvoice() {
      let self = this

      if (!self.isAccepting && !self.isRejecting) {
        self.isRejecting = true

        invoiceProvider.methods
          .rejectInvoice(self.invoice.Id)
          .then((response) => {
            if (response.status === 200) {
              self.setInvoice(response.data)
            }
          })
          .finally(() => {
            self.isRejecting = false
          })
      }
    },

    acceptInvoice() {
      let self = this

      if (!self.isAccepting && !self.isRejecting) {
        self.isAccepting = true

        invoiceProvider.methods
          .acceptInvoice(self.invoice.Id)
          .then((response) => {
            if (response.status === 200) {
              self.setInvoice(response.data)
            }
          })
          .finally(() => {
            self.isAccepting = false
          })
      }
    },
  },
}
</script>

<style></style>
